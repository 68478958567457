const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const MEETING_URL = `${process.env.REACT_APP_MEETING_URL}`;
export const CHAT_URI = `${process.env.REACT_APP_CHAT_BASE_URL}`
export const BASE_URL = `${BACKEND_URL}/`;

export const SOCKET_URL = `${BACKEND_URL}`;
export const CONTACT_URL = "api/contact/";
export const COMPANY_URL = "api/company/";
export const USER_URL = "api/user/";
export const STORAGE_URL = "api/storage/";
export const CHAT_URL = "api/chat/create-dialog/";

export const CREATE_CHAT = "user.createChat";
export const SHARE_CONTACT = "user/{hash}/share";
export const SEARCH_CONTACT = "search.contact?s=";
export const CURRENT_PROFILE = "biz.getProfile";
export const PROFILE_GET = "profile.get";
export const GET_FRIENDS = "user.get";
// export const GET_TEAMMATES = "user.getContactCompany";
export const GET_INCOMING_REQUESTS = "user.getRequestsInbox";
export const GET_OUTGOING_REQUESTS = "user.getRequestsOutgoing";
export const GET_LAST_ONLINE = "user.getLastOnline?hash=";
export const ADD_FRIEND = "user/add?ids=";
export const BLOCK_CONTACT_URL = "user.setBlock?user_id=";
export const UNBLOCK_CONTACT_URL = "user.setUnBlock?user_id=";
export const GET_TEAMMATES = "biz.getUsers?company_id="
