import {isElectron} from "@clout-team/helpers/dist/utils/web-utils"

import {
  acceptRequestFromUser,
  blockUser,
  makeRequestToUser,
  rejectRequestFromUser,
  revokeRequestToUser,
  unblockOrDeleteUser,
} from "../../api/contact";
import {getProfile} from "../../api/profile";
import {
  getFriends,
  getLastOnline,
  getRequestsInbox,
  getRequestsOutgoing,
} from "../../api/user";
import {Contact} from "../../interfaces/contact";
import * as TYPES from "../actionTypes/actionTypes";
import {initContacts, initProfile} from "../localDB";

export const setCurrentUser = () => async (dispatch: any) => {
  // TODO УБРАТЬ ПОТОМ КОГДА ЭЛЕКТРОН БУДЕТ С НОВОЙ КОНТАКНОЙ КНИГЕ!!!
  // if (isElectron())
  //   initProfile();
  // else {
    const myself = await getProfile();
    dispatch({
      type: TYPES.SET_CURRENT_USER,
      payload: myself,
    });
  // }
};

export const setCurrentContact = (currentContactId: number | null | string) => ({
  type: TYPES.SET_CURRENT_CONTACT,
  payload: currentContactId,
});

export const setFoundContact = (foundContact: {
  contact: Contact | {};
  searchPhrase: string;
}) => ({
  type: TYPES.SET_FOUND_CONTACT,
  payload: foundContact,
});

export const setCurrentContactsTab = (currentTab: number) => ({
  type: TYPES.SET_CURRENT_CONTACTS_TAB,
  payload: currentTab,
});

export const setCurrentModal = (currentModal: string | null) => ({
  type: TYPES.SET_CURRENT_MODAL,
  payload: currentModal,
});

export const setMyContactsList = () => async (dispatch: any) => {
  let updatedFriends: any;
  // TODO УБРАТЬ ПОТОМ КОГДА ЭЛЕКТРОН БУДЕТ С НОВОЙ КОНТАКНОЙ КНИГЕ!!!
  // if( isElectron() ) {
  //     initContacts();
  //     return;
  // }
  // else

  let nameOfCompany = ""
  getProfile().then(res => {
      nameOfCompany = res?.company.domain || res?.domain
  })
  updatedFriends = (await getFriends());
  let systemContacts = await Promise.all(
    updatedFriends.response.system.map(async (systemContact: Contact) => {
      return {
        ...systemContact,
        status_user: "SYSTEM",
        lastEnter: await getLastOnline(systemContact.hash),
      };
    })
  );

  let recentContacts = updatedFriends.response.contact.slice(0, 3);
  let acceptedList = await Promise.all(
    updatedFriends.response.contact.map(async (friendContact: Contact) => {
      if (friendContact.is_blocked) {
        return {
          ...friendContact,
          status_user: "BLOCKED",
          group: friendContact.name.charAt(0),
          lastEnter: await getLastOnline(friendContact.hash),
        };
      }
      return {
        ...friendContact,
        status_user: "ACCEPTED",
        group: friendContact.name.charAt(0),
        lastEnter: await getLastOnline(friendContact.hash),
      };
    })
  );

    let teamMatesList = await Promise.all(
      updatedFriends.response.company.filter((item: Contact) => !['DEACTIVATED', "DELETED", "BLOCKED"].includes(String(item.status))).map(async (item: Contact) => {
        if (item.is_friend) {
          const lastOnline  = await getLastOnline(item.hash);
          if (!acceptedList.some(a => JSON.stringify(a) === JSON.stringify({...item, status_user: "ACCEPTED", group: item.name.charAt(0), lastEnter: lastOnline,}))) {
              // acceptedList.push({
              //     ...item,
              //     status_user: "ACCEPTED",
              //     group: item.name.charAt(0),
              //     lastEnter: lastOnline,
              // })
          }
          return {
            ...item,
            status_user: 'TEAMMATE_FRIEND',
            lastEnter: lastOnline,
          }
        } else {
          return {
            ...item,
            status_user: 'TEAMMATE',
            lastEnter: await getLastOnline(item.hash),
          }
        }
      })
    )
    // let {response: incomingContacts}: any = (await getRequestsInbox());
    // let {response: outgoingContacts}: any = (await getRequestsOutgoing());
  const allContacts = {
    myCompany: nameOfCompany,
    recent: recentContacts,
    system: systemContacts,
    accepted: acceptedList,
    teammates: teamMatesList,
    incoming: [],
    outgoing: []
  }

  dispatch({
    type: TYPES.SET_MY_CONTACTS_LIST,
    payload: {
      myCompany: nameOfCompany,
      recent: recentContacts,
      system: systemContacts,
      accepted: acceptedList,
      teammates: teamMatesList,
      incoming: [],
      outgoing: [],
    },
  });

  return allContacts

};

/*---------------------- изменение отношений ------------------ */

export const makeRequestToUserAction =
  (idOfPotentialFriend: string, welcomingMessage: string) =>
    async (dispatch: any, getState: any) => {
      try {
        let response = await makeRequestToUser(
          idOfPotentialFriend,
          welcomingMessage
        );
        if (response.ok) {
          const myContactsList = getState().myContactsList;
          const currentContact = getState().currentContact;
          const foundContact = getState().foundContact;

          dispatch({
            type: TYPES.SET_MY_CONTACTS_LIST,
            payload: {
              ...myContactsList,
              teammates: myContactsList.teammates.map(
                (oneTeammateContact: Contact) => {
                  if (oneTeammateContact.hash === idOfPotentialFriend) {
                    oneTeammateContact.status = "SENT_REQUEST";
                  }
                  return oneTeammateContact;
                }
              ),
              /*outgoing: [
                ...myContactsList.outgoing,
                ...myContactsList.teammates.filter(
                  (oneTeammateContact: Contact) =>
                    oneTeammateContact.hash === idOfPotentialFriend
                ),
              ],*/
            },
          });
          if (currentContact?.hash === idOfPotentialFriend) {
            dispatch(
              setCurrentContact({
                ...myContactsList.teammates.filter(
                  (oneTeammateContact: Contact) =>
                    oneTeammateContact.hash === idOfPotentialFriend
                )[0],
                status: "SENT_REQUEST",
              })
            );
          }
          if (foundContact.contact?.hash === idOfPotentialFriend) {
            dispatch(
              setFoundContact({
                contact: {
                  ...myContactsList.teammates.filter(
                    (oneTeammateContact: Contact) =>
                      oneTeammateContact?.hash === idOfPotentialFriend
                  )[0],
                  status: "SENT_REQUEST",
                },
                searchPhrase: foundContact.searchPhrase,
              })
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

export const acceptRequestFromUserAction =
  (idOfFutureFriend: string) => async (dispatch: any, getState: any) => {
    try {
      let response = await acceptRequestFromUser(idOfFutureFriend);
      if (response.ok) {
        const myContactsList = getState().myContactsList;
        const currentContact = getState().currentContact;
        const foundContact = getState().foundContact;

        dispatch({
          type: TYPES.SET_MY_CONTACTS_LIST,
          payload: {
            ...myContactsList,
            accepted: [
              ...myContactsList.accepted,
              ...myContactsList.teammates.filter(
                (oneTeammateContact: Contact) =>
                  oneTeammateContact.hash === idOfFutureFriend
              ),
            ],
            teammates: myContactsList.teammates.map(
              (oneTeammateContact: Contact) => {
                if (oneTeammateContact.hash === idOfFutureFriend) {
                  oneTeammateContact.status = "ACCEPTED";
                }
                return oneTeammateContact;
              }
            ),
            /*incoming: myContactsList.incoming.filter(
              (oneIncomingContact: Contact) =>
                oneIncomingContact.hash !== idOfFutureFriend
            ),*/
          },
        });
        if (currentContact?.hash === idOfFutureFriend) {
          dispatch(
            setCurrentContact({
              ...myContactsList.teammates.filter(
                (oneTeammateContact: Contact) =>
                  oneTeammateContact.hash === idOfFutureFriend
              )[0],
              status: "ACCEPTED",
            })
          );
        }
        if (foundContact.contact.hash === idOfFutureFriend) {
          dispatch(
            setFoundContact({
              contact: {
                ...myContactsList.teammates.filter(
                  (oneTeammateContact: Contact) =>
                    oneTeammateContact.hash === idOfFutureFriend
                )[0],
                status: "ACCEPTED",
              },
              searchPhrase: foundContact.searchPhrase,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const rejectRequestFromUserAction =
  (idOfUser: string) => async (dispatch: any, getState: any) => {
    try {
      let response = await rejectRequestFromUser(idOfUser);
      if (response.ok) {
        const myContactsList = getState().myContactsList;
        const currentContact = getState().currentContact;
        const foundContact = getState().foundContact;

        dispatch({
          type: TYPES.SET_MY_CONTACTS_LIST,
          payload: {
            ...myContactsList,
            teammates: myContactsList.teammates.map(
              (oneTeammateContact: Contact) => {
                if (oneTeammateContact.hash === idOfUser) {
                  oneTeammateContact.status = null;
                }
                return oneTeammateContact;
              }
            ),
            /*incoming: myContactsList.incoming.filter(
              (oneIncomingContact: Contact) =>
                oneIncomingContact.hash !== idOfUser
            ),*/
          },
        });
        if (currentContact?.hash === idOfUser) {
          dispatch(setCurrentContact(''));
        }
        if (foundContact.contact.hash === idOfUser) {
          dispatch(
            setFoundContact({
              contact: {
                ...myContactsList.teammates.filter(
                  (oneTeammateContact: Contact) =>
                    oneTeammateContact.hash === idOfUser
                )[0],
                status: null,
              },
              searchPhrase: foundContact.searchPhrase,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const revokeRequestToUserAction =
  (idOfUser: string) => async (dispatch: any, getState: any) => {
    try {
      let response = await revokeRequestToUser(idOfUser);
      if (response.ok) {
        const myContactsList = getState().myContactsList;
        const currentContact = getState().currentContact;
        const foundContact = getState().foundContact;

        dispatch({
          type: TYPES.SET_MY_CONTACTS_LIST,
          payload: {
            ...myContactsList,
            teammates: myContactsList.teammates.map(
              (oneTeammateContact: Contact) => {
                if (oneTeammateContact.hash === idOfUser) {
                  oneTeammateContact.status = null;
                }
                return oneTeammateContact;
              }
            ),
            /*outgoing: myContactsList.outgoing.filter(
              (oneOutgoingContact: Contact) =>
                oneOutgoingContact.hash !== idOfUser
            ),*/
          },
        });
        if (currentContact?.hash === idOfUser) {
          dispatch(setCurrentContact(''));
        }
        if (foundContact.contact.hash === idOfUser) {
          dispatch(
            setFoundContact({
              contact: {
                ...myContactsList.teammates.filter(
                  (oneTeammateContact: Contact) =>
                    oneTeammateContact.hash === idOfUser
                )[0],
                status: null,
              },
              searchPhrase: foundContact.searchPhrase,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const blockUserAction =
  (idOfUser: string) => async (dispatch: any, getState: any) => {
    try {
      let response = await blockUser(idOfUser);
      if (response.ok) {
        const myContactsList = getState().myContactsList;
        const currentContact = getState().currentContact;
        const foundContact = getState().foundContact;

        dispatch({
          type: TYPES.SET_MY_CONTACTS_LIST,
          payload: {
            ...myContactsList,
            accepted: myContactsList.accepted.filter(
              (oneAceptedContact: Contact) => oneAceptedContact.hash !== idOfUser
            ),
            teammates: myContactsList.teammates.map(
              (oneTeammateContact: Contact) => {
                if (oneTeammateContact.hash === idOfUser) {
                  oneTeammateContact.status = "BLOCKED";
                }
                return oneTeammateContact;
              }
            ),
          },
        });
        if (currentContact?.hash === idOfUser) {
          dispatch(
            setCurrentContact({
              ...myContactsList.teammates.filter(
                (oneTeammateContact: Contact) =>
                  oneTeammateContact.hash === idOfUser
              )[0],
              status: "BLOCKED",
            })
          );
        }
        if (foundContact.contact.hash === idOfUser) {
          dispatch(
            setFoundContact({
              contact: {
                ...myContactsList.teammates.filter(
                  (oneTeammateContact: Contact) =>
                    oneTeammateContact.hash === idOfUser
                )[0],
                status: "BLOCKED",
              },
              searchPhrase: foundContact.searchPhrase,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const unblockOrDeleteUserAction =
  (idOfUserToUnbclockOrDelete: string) =>
    async (dispatch: any, getState: any) => {
      try {
        let response = await unblockOrDeleteUser(idOfUserToUnbclockOrDelete);
        if (response.ok) {
          const myContactsList = getState().myContactsList;
          const currentContact = getState().currentContact;
          const foundContact = getState().foundContact;

          dispatch({
            type: TYPES.SET_MY_CONTACTS_LIST,
            payload: {
              ...myContactsList,
              accepted: myContactsList.accepted.filter(
                (oneAceptedContact: Contact) =>
                  oneAceptedContact.hash !== idOfUserToUnbclockOrDelete
              ),
              teammates: myContactsList.teammates.map(
                (oneTeammateContact: Contact) => {
                  if (oneTeammateContact.hash === idOfUserToUnbclockOrDelete) {
                    oneTeammateContact.status = null;
                  }
                  return oneTeammateContact;
                }
              ),
            },
          });
          if (currentContact?.hash === idOfUserToUnbclockOrDelete) {
            dispatch(
              setCurrentContact({
                ...myContactsList.teammates.filter(
                  (oneTeammateContact: Contact) =>
                    oneTeammateContact.hash === idOfUserToUnbclockOrDelete
                )[0],
                status: null,
              })
            );
          }
          if (foundContact.contact.hash === idOfUserToUnbclockOrDelete) {
            dispatch(
              setFoundContact({
                contact: {
                  ...myContactsList.teammates.filter(
                    (oneTeammateContact: Contact) =>
                      oneTeammateContact.hash === idOfUserToUnbclockOrDelete
                  )[0],
                  status: null,
                },
                searchPhrase: foundContact.searchPhrase,
              })
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

/*  ////////// Переход в чат   ////////////////// */

export const setActiveChatAction = (chatId: string) =>
  (dispatch: any) => {
    dispatch({
      type: TYPES.SET_ACTIVE_CHAT,
      payload: chatId,
    })
    // TODO УБРАТЬ ПОТОМ КОГДА ЭЛЕКТРОН БУДЕТ С НОВОЙ КОНТАКНОЙ КНИГЕ!!!
    // if (isElectron()) {
      // @ts-ignore
    //   window.ipcRenderer.send('request-open-chat-or-message', chatId)
    // } else {
      window.open(`${process.env.REACT_APP_CHAT_BASE_URL}${chatId}`)
    // }
  }
